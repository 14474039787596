.form-6grid-main {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 34px;
}

.form-3grid-main {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-flex {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.form-flex-1 {
  flex: 3.2;
}

.cards {
  margin-top: 20px;
}

.inventory-div {
  border-top: 1px dashed #c4cdd5;
  padding-top: 20px;
}

.inventory-div1 {
  border-top: 1px dashed #c4cdd5;
  padding: 10px 2px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  // margin-top: -6px;
}

.inventory-div2 {
  border-top: 1px dashed #c4cdd5;
  padding: 20px 2px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.total-name {
  color: #637381;
  font-weight: 400;
  font-size: 16px;
}

.total-name1 {
    color: #637381;
    font-weight: 400;
    font-size: 16px;
    margin-top: 1px;
  }

.order-total {
  margin-top: 5px;
}

.total {
  font-weight: 700;
  font-size: 18px;
  color: #212b36;
}

.btn-div {
  width: 30%;
  margin-top: 15px;
}

.customer_name {
  margin: -40px 0px -20px 5px;
}
