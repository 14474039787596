.main {
    display: flex;
    height: 100vh;
}

.section-one {
    background-color: #171717;
    flex: 2;
    color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section-one >p {
    font-weight: 700;
    font-size: 24px;
    font-family: 'Public Sans';
    font-style: normal;
}

.img-box {
    width: 400px;
    height: 400px;
    border-radius: 50%;
}

.logo-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;

}

.section-two {
    flex: 0.8;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
}

.section-two >p {
    font-weight: 700;
    font-size: 24px;
    font-family: 'Public Sans';
    font-style: normal;
    align-items: left;
    text-align: left;
    width: 100%;
    margin-bottom: 0;
}

.btn-div {
    margin-top: -12px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error {
    color: red;
    margin: -10px 0 -10px;
}