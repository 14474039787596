.form-flex {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.form-flex-1 {
  width: 55%;
}

.form-flex-2 {
  width: 35%;
}

.form-flex-3 {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #f4f6f8;
  cursor: pointer;
}

.form-flex-4 {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.delete-icon svg {
  width: 16px;
  height: 16px;
}

.btn-div {
  width: 30%;
  margin-top: 15px;
}

.price-span {
  width: 150%;
  margin-left: -32px;
  margin-top: 10px;
  color: #637381;
  font-weight: 400;
  font-size: 14px;
}

.total-price {
  color: #212b36;
}
