.dash-main {
  padding: 30px 0px;
}

.section2 {
  margin-top: 20px;
  // height: 520px;
  // border: 2px solid red;
}

.progressDiv {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.progressDiv1 {
  height: 60%;
  // border: 2px solid red;
}

.progressDiv2 {
  height: 30%;
}
