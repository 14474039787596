

.head {
    display: flex;
    width: 94%;
    padding: 10px 28px;
    border-bottom: 1px dashed #C4CDD5;
    color: #919EAB;
    font-weight: 400;
    font-size: 16px;
}

.hd-one {
    flex: 2;
}

.hd-two, .hd-three, .hd-four {
    flex: 0.5;
    text-align: right;
}

.body {
    display: flex;
    width: 94%;
    padding: 25px 28px;
    border-bottom: 1px dashed #C4CDD5;
    color: #212B36;
    font-weight: 400;
    font-size: 16px;
}

.bd-one {
    flex: 2;
}

.bd-two, .bd-three, .bd-four {
    flex: 0.5;
    text-align: right;
}

.total {
    display: flex;
    width: 94%;
    padding: 10px 28px;
    color: #212B36;
    font-weight: 400;
    font-size: 16px;
}

.weigh {
    font-weight: 700;
}

.discount {
    color: #B71D18;
}