@import "../../../../UI/Colors.scss";

.modal {
    padding: 10px;
    width: 600px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
}

.card {
    display: flex;
    padding: 14px;
    background: rgba(230, 233, 235, 0.3);
    border-radius: 8px;
}

.textCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 3;
    gap: 5px;
}

.action-m {
    background-color: #ffffff;
    border-radius: 7px;
    z-index: 10;
    padding: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    //right: 480px;
    left: 470px;
    margin-top: 16px;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
    gap: 5px;
    cursor: pointer; 
}

.circle-icon {
    transform: rotate(90deg);
}

.circle-icon svg {
    width: 15px;
    height: 15px;
    margin-top: 4px;
    cursor: pointer;
  }

.pending {
    padding: 4px 7px;
    color: #FF0000;;
    background: rgba(255, 0, 0, 0.1);
    font-weight: 400;
    font-size: 12px;
    border-radius: 8px;
}

.paid {
    color: #36b37e;
    background: rgba(54, 179, 126, 0.2);
    padding: 4px 7px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
}

.title {
    font-weight: 700;
    font-size: 14px;
    color: #454F5B;
    margin: 0;
}

.extra {
    font-weight: 500;
    font-size: 12px;
    color: #919EAB;
    margin: 0;
}

.reason {
    font-weight: 700;
}

.items {
    padding: 4px;
    width: 100%;
    gap: 6px;
    display: flex;
    white-space: nowrap;
}

.chevron-icon svg {
    height: 18px;
    width: 18px;
}

.edit-text {
    color: #503FA5;
}

.items:hover {
    cursor: pointer;
    background-color: $grey-100 !important;
}