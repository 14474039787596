@import "../../Colors.scss";

.form-group {
  width: 100%;
}

.form-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .file-select {
    cursor: pointer;
    border: 1px dashed $grey-300;
    border-radius: 8px;
    background: $grey-200;
    display: inline-block;
    input {
      position: absolute;
      top: -1000px;
    }
    &:hover {
      background: rgba(223, 227, 232, 0.4);
    }
    &:active {
      border: 1px dashed $grey-700;
    }
    .file-input-ui-container {
      display: flex;
      padding: 24px;
      align-items: center;
      gap: 24px;
      .file-image {
        padding: 26px 16px;
        background: #ffffff;
        border-radius: 20px;
      }
      .selected-image img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
      .selected-image {
        width: 60px;
        height: 60px;
        border-radius: 20px;
      }
      .file-image-description {
        height: max-content;
        .file-input-heading {
          color: $grey-700;
        }
      }
    }
  }
}
