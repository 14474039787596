@import "../../UI//Colors.scss";

.main {
  padding: 10px 0px;
  z-index: -1;
}

.form-6grid-main {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 34px;
}

.send-payment {
  background-color: #ffffff;
  border-radius: 7px;
  z-index: 10;
  padding: 10px 10px 0px 10px;
  display: flex;
  //justify-content: left;
  // margin-right: -60px;
  // margin-top: -20px;
  right: 60px;
  top: 50px;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
  position: absolute;
}

.section-two {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
}

.section-an {
  flex-basis: 50%;
  display: flex;
}

.section-a {
  flex-basis: 50%;
}

.section-a-1 {
  flex-basis: 100%;
}

.reference {
  margin-top: 20px;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 0;
}

.customer-details {
  display: flex;
  gap: 40px;
  margin-top: 18px;
}

.delivery-details {
  display: flex;
  gap: 45px;
  margin-top: 18px;
}

.user-name {
  color: #b71d18;
  font-weight: 700;
  font-size: 20px;
  margin-top: 15px;
}

.user-email {
  font-weight: 400;
  font-size: 12px;
  color: #b71d18;
  text-decoration: underline;
  margin-top: 0;
}

.address-title {
  color: #c4cdd5;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
}

.recipient-name {
  font-weight: 700;
  font-size: 14px;
}

.details {
  font-weight: 400;
  font-size: 12px;
  margin-top: 0;
  color: #000000;
}

.details-two {
  display: flex;
  gap: 4px;
  margin-top: 0;
}

.section-a-2 {
    margin-top: 35px;
}

.table-section {
    width: 106%;
    margin-left: -30px;
}

.mains {
  padding: 10px;
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal {
  padding: 10px;
  width: 600px;
  height: 100%;
}
