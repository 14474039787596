@import "../../UI/Colors.scss";

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh;
}

.header {
    color: #F36766;
    font-size: 3.5em;
}

button {
    background-color: #F36766;
    border: none;
    border-radius: 12px;
    padding: 10px;
    color: #FFF;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}

.lineOne {
    color: #939091;
    font-size: 1.5em;
    margin-bottom: 0;
}

.lineTwo {
    color: #BABABA;
    font-size: 1em;
    margin-top: 8px;
}