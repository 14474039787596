@import "../../UI/Colors.scss";

.top {
  display: flex;
  justify-content: space-between;
}

.topDiv {
  margin-bottom: 40px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 1px;
}

.div1 {
  display: flex;
  gap: 10px;
}

.text1 {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey-700;
}

.text2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey-700;
  margin-top: -7px;
}

.text3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey-500;
}

.addDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.text3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey-500;
}
