@import "../../../../UI/Colors.scss";

.cardDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 0px;
    gap: 20px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
    border-radius: 16px 16px 2px 2px;
}

.title {
    font-weight: 700;
    font-size: 18px;
    color: #4A4A4A;
    margin-left: -20px;
}

.downSide {
    background: #FFFFFF;
    border-top: 1px solid rgba(145, 158, 171, 0.24);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px;
    gap: 20px;
    width: 90%;
}

.circle {
    margin-top: -10px;
    box-sizing: border-box;
    width: 248px;
    height: 220px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
}

.circleText  {
    position: absolute;
    text-align: center;
    align-content: center;
}

.download {
    font-weight: 500;
}

.downloadText {
font-weight: 700;
font-size: 16px;
}

.redCircle {
   background: #B71D18;
   width: 6px;
   height: 6px; 
   border-radius: 50%; 
}

.greyCircle {
    width: 6px;
    height: 6px;
    background: $grey-400;
    border-radius: 50%;
}

.divv {
    display: flex;
    gap: 4px;
}

.divText {
    font-weight: 400;
    font-size: 14px;
    color: $grey-700;
    margin-top: -6px;
}
