@import "../Colors.scss";

.darkBG {
    background-color: transparent;
    width: 300px;
    height: 300px;
    top: 120%;
    z-index: 10;
    // top: -50%;
    right: -300%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.darkBG2 {
    background-color: transparent;
    width: 300px;
    height: 300px;
    top: 120%;
    z-index: 10;
    // top: -50%;
    right: -300%;
    transform: translate(-50%, -50%);
    position: absolute;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    padding: 8px 12px;
}

li:hover {
    cursor: pointer;
    background-color: $grey-100 !important;
}

.centered {
    position: fixed;
    top: 30.5%;
    left: 70%;
    z-index: 10;
    transform: translate(-50%, -50%);
    // width: 120px;
    // height: 80px;
    display: flex;
    flex-direction: column;
    width: 100px;
    padding: 4px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
    border-radius: 8px;
}

.centered2 {
    position: fixed;
    top: 30.5%;
    left: 63%;
    z-index: 10;
    transform: translate(-50%, -50%);
    // width: 120px;
    // height: 80px;
    display: flex;
    flex-direction: column;
    // width: 130px;
    padding: 4px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
    border-radius: 8px;
}

.hover {
    padding: 4px;
    width: 100%;
    gap: 7px;
    display: flex;
}

.hover:hover {
    cursor: pointer;
    background-color: $grey-100 !important;
}

.modal {
    width: 250px;
    height: 170px;
    background: white;
    color: white;
    z-index: 10;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.trashIcon {
    display: flex;
    justify-content: column;
}

.delete-text {
    color: #B71D18;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-top: 2px;
}

.edit-text {
    color: #161C24;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-top: 2px;
}

.delete-icon svg {
    width: 16px;
    height: 16px;
}

.view-icon svg {
    width: 16px;
    height: 16px;
}

.edit-icon svg path {
    // fill: #0000;
    opacity: 1;
    background-color: black;
}

.chevron-icon svg {
    width: 18px;
    height: 18px;
}