$grey-300: #dfe3e8;
$grey-400: #c4cdd5;
$grey-500: #919eab;
$grey-600: #637381;
$grey-700: #454f5b;
$grey-800: #212b36;
$grey-900: #161c24;
$success: #36b37e;
.form-6grid-main {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 34px;
}

.form-3grid-main {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-flex {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.form-flex-1 {
  flex: 3.2;
}

.cards {
  margin-top: 20px;
}

.inventory-div {
  border-top: 1px dashed #c4cdd5;
  padding-top: 20px;
}

.btn-div {
  width: 30%;
  margin-top: 15px;
}
.add-on-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 8px;
  .desc {
    font-size: 12px;
    color: $grey-600;
  }
  .add-on {
    display: flex;
    flex-direction: column;
    gap: 15px;
    // background: $grey-300;
    .bg-wrap {
      display: flex;
      flex-direction: column;
      padding: 10px 20px;
      background-color: #dfe3e81c;
      width: 100%;
      border-radius: 8px;
      gap: 10px;
      max-width: 550px;

      .row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 30px;
        row-gap: 16px;
        margin-top: 10px;
      }
      .selected_items {
        display: flex;
        align-items: center;
        gap: 14px;
        overflow-x: auto;

        .item {
          display: flex;
          align-items: center;
          gap: 10px;
          border: 1px solid $grey-400;
          border-radius: 8px;
          padding: 4px;
          img {
            max-width: 24px;
            width: fit-content;
            height: 24px;
            border-radius: 40px;
            background: #161c240a;
          }
          span {
            font-size: 13px;
            white-space: nowrap;
          }
          .icon {
            cursor: pointer;
          }
        }
        &::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
      }
    }
  }
}
