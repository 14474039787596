.uigrid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 20px;
  margin: 0px auto;
  align-content: center;
  justify-content: center;
  /* min-height: 100vh; */
}

.uigrid-span-12 {
  grid-template-columns: repeat(12, 1fr);
}

.uigrid-span-9 {
  grid-template-columns: repeat(9, 1fr);
}

@media only screen and (min-width: 1360px) {
  .uigrid {
    max-width: 1360px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1099px) {
  .uigrid {
    max-width: 1000px;
  }
}

/* Tab Settings */
@media only screen and (max-width: 999px) {
  .uigrid {
    grid-row-gap: 10px;
    max-width: 690px;
  }
}

/* Mobile Setting */
@media only screen and (max-width: 500px) {
  .uigrid {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    max-width: 374px;
  }
}

.uigrid-12,
.uigrid-11,
.uigrid-10,
.uigrid-9,
.uigrid-8,
.uigrid-7,
.uigrid-6,
.uigrid-5,
.uigrid-4,
.uigrid-3,
.uigrid-2,
.uigrid-1 {
  height: 100vh;
}

.uigrid-12 {
  grid-column-start: 1;
  grid-column-end: -1;
}
.uigrid-11 {
  grid-column: span 11;
}
.uigrid-10 {
  grid-column: span 10;
}
.uigrid-9 {
  grid-column: span 9;
}
.uigrid-8 {
  grid-column: span 8;
}
.uigrid-7 {
  grid-column: span 7;
}
.uigrid-6 {
  grid-column: span 6;
}
.uigrid-5 {
  grid-column: span 5;
}
.uigrid-4 {
  grid-column: span 4;
}
.uigrid-3 {
  grid-column: span 3;
}
.uigrid-2 {
  grid-column: span 2;
}
.uigrid-1 {
  grid-column: span 1;
}
.main-section {
  min-height: unset;
}
.section2 {
  height: auto;
}
