
.cardDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 21px;
    background: #1939B7;
    border-radius: 16px;
    padding: 26px 16px;
}

.side1 {
    width: 60.67px;
    height: 60.67px;
    opacity: 2;
}

.side2 {
    display: flex;
    flex-direction: column;
}

.orders {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #FFFFFF;
    z-index: 1;
}

.pending {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    z-index: 1;
}

.cart-icon svg {
    opacity: 0.4;
    transform: rotate(-27.82deg);
    position: absolute;  
    right: 51.27%;
    top: 88.5%;
    bottom: 4.26%;
    width: 130.03px;
    height: 80.67px;
}

.cart-icon svg path {
    fill: #FFFFFF;
    opacity: 0.4;
}