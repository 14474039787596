.toggle-box {
    display: flex;
    gap: 10px,
}

.title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #454F5B;
    margin-top: 3px;
}

.toggle-container {
    cursor: pointer;
    user-select: none;
    position: relative;
    background: #B71D18;
    border-radius: 10px;
    height: 20px;
    width: 38px;
    border-radius: 10px;
}

.toggle-container-disabled {
    background: rgba(145, 158, 171, 0.24);
}

.dialog-button {
    font-size: 14px;
    line-height: 16px;
    height: 18px;
    width: 18px;
    left: 19px;
    top: 1px;
    cursor: pointer;
    background-color: white;
    color: white;
    border-radius: 18px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: unset;
    border-radius: 50%;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    transition: all 0.3s ease;
}

.disabled {
    left: 2px;
    top: 1px;
}