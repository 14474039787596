@import "../../../Components/UI/Colors.scss";

.form-6grid-main {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 34px;
}

.form-3grid-main {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-flex {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.form-flex-1, .form-flex-2 {
  flex: 2;
}

.cards {
  margin-top: 20px;
}

.inventory-div {
  border-top: 1px solid #C4CDD5;
  padding-top: 20px;
}

.btn-div {
  width: 30%;
  margin-top: 15px;
}

.whatsapp {
  margin-top: -15px;
  margin-bottom: 1px;
  color: $grey-500;
  font-size: 13px !important;
}
