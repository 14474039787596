@import "../../../../UI/Colors.scss";
$eadRed: #b71d18;
$eadRedContrast: rgb(183, 29, 24, 0.2);

.trend-up-icon svg path {
  fill: $success;
  background-color: $success;
}

.status {
    padding: 2px 8px;
    border-radius: 12px;
    font-weight: 400;
    font-size: 12px;
}

.statusDiv {
    display: flex;
    justify-content: space-between;
  }

.circle-icon svg {
    width: 15px;
    height: 15px;
    margin-top: 4px;
    cursor: pointer;
}

.nameDiv {
  display: flex;
  gap: 3px;
}

.imageDiv {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.imageName {
  margin-top: 4px;
}