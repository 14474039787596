@import "../../Colors.scss";
.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  .checkbox {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all ease-in-out 300ms;
    .icon {
      font-size: 18px;
      color: red;
      transition: all ease-in-out 300ms;
    }
    .active {
      opacity: 1;
    }
    .inactive {
      opacity: 0;
    }
  }
  .active_ {
    border: 1px solid red;
  }
  .inactive_ {
    border: 1px solid gray;
  }
  .label {
    font-size: 14px;
    color: gray;
    text-transform: capitalize;
  }
}
