@import "./Colors.scss";

body {
  font-family: "Public Sans", sans-serif;
  color: $grey-800;
}
h1 {
  font-size: 64px;
  line-height: 80px;
  font-weight: 800;
  margin: 0 !important;
}
h2 {
  font-size: 48px;
  font-weight: 800;
  line-height: 64px;
  margin: 0 !important;
}
h3 {
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  margin: 0 !important;
}
h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin: 0 !important;
}
h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin: 0 !important;
}
h6 {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  margin: 0 !important;
}

.subtitle-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.subtitle-2 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.body-1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.body-2 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

.caption {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  margin: 0 !important;
}

.overline {
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-transform: uppercase;
  margin: none !important;
}
