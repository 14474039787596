@import "../../Colors.scss";

.form-group {
  width: 100%;
  border: 2px solid red;
}

.search-container {
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 2px;
    border: 1px solid $grey-300;
    font-size: 15px;
    border-radius: 3px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    max-height: 200px;
    overflow: auto;
    z-index: 9;
    top: 59px;
    left: -1px
}

.search-container::-webkit-scrollbar {
    display: none;
  }
.search-text {
    padding: 8px;
    cursor: pointer;
}

.search-text:hover {
    background-color: $grey-200;
}

.addBtn {
    display: flex;
    background: #3366FF;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;
    cursor: pointer;
    color: #FFFFFF;
  }


.form-container {
  width: 100%;
  position: relative;
  display: flex;
  input {
    min-width: 100%;
    padding: 16px 0 16px 24px;
    border: 1px solid $grey-300;
    font-size: 14px;
    border-radius: 8px;
    color: $grey-700;
    transition: all 0.5s ease-in-out;
    height: 44px;
    &:hover {
      border-color: $grey-700;
    }
    &:focus {
      border-color: $grey-700;
      outline: none;
      + label {
        padding: 0 3px;
        background: #ffffff;
        color: $grey-700 !important;
        top: 0%;
        color: $grey-500;
        font-size: 12px !important;
        left: 24px;
      }
    }
    &:not(:placeholder-shown) {
      border-color: $grey-300;
      outline: none;
      + label {
        padding: 0 3px;
        background: #ffffff;
        color: $grey-700 !important;
        top: 0%;
        color: $grey-500;
        font-size: 12px !important;
        left: 24px;
      }
    }
  }

  .loading-icon {
    position: absolute;
    left: 92%;
    top: 22%;
  }

  label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $grey-500;
    font-size: 14px;
    left: 24px;
    transition: all 0.5s ease-in-out;
  }
}

.add-icon svg path {
    fill: #FFFFFF;
    opacity: 1;
    background-color: #FFFFFF;
    border: 2px solid white;
  }
