@import "../../UI/Colors.scss";

.main {
  padding: 10px 30px 10px 0px;
}

.mains {
  padding: 10px;
  width: 600px;
  height: 100%;
}
.topDiv {
  margin-bottom: 40px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 1px;
}

.section2 {
  margin-top: 30px;
}

.div1 {
  display: flex;
  gap: 3px;
}

.text1 {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey-700;
}

.text2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey-700;
  margin-top: -7px;
}
.text3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey-500;
}
