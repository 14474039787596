.form-6grid-main {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 34px;
}

.form-3grid-main {
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-flex {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.form-flex-1 {
  flex: 3.2;
}

.cards {
  margin-top: 20px;
}

.cardss {
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
  gap: 21px;
}

.cardss-div1 {
  width: 54%;
}

.cardss-div2 {
  width: 40%;
}


.inventory-div {
  border-top: 1px solid #C4CDD5;
  padding-top: 20px;
}

.btn-div {
  width: 30%;
  margin-top: 15px;
}
