@import "../../UI/Colors.scss";
$eadRed: #b71d18;
$eadRedContrast: rgb(183, 29, 24, 0.1);

.sidebar-main {
  background: #ffffff;
  padding: 0 10px 0;
  text-align: left;
  min-height: 100vh;
  border-right: 1px dashed rgba(145, 158, 171, 0.24);
  position: fixed;
  overflow-y: scroll;
  height: 100%;
}

.sidebar-main::-webkit-scrollbar {
  display: none;
}

.sidebar-menu {
  margin-top: 40px;
}

.admin-logo {
  padding: 40px 0 0 10px;
}

.menu-item,
.submenu-item {
  display: flex;
  gap: 16px;
  text-decoration: none;
  color: $grey-600;
  border-radius: 8px;
  transition: all 0.5s ease;
  cursor: pointer;
}

.menu-item {
  padding: 14px;
  justify-content: space-between;
}

.menu-side-one {
  display: flex;
  gap: 10px;
}

.submenu-item {
  padding: 14px 14px 14px 34px;
}

.menu-item:hover,
.submenu-item:hover {
  background-color: $grey-100;
}

.menu-item-group:first-child {
  margin-bottom: 10px !important;
}

.menu-item-title,
.submenu-item-title {
  height: max-content;
}

.menu-item-icon,
.submenu-item-icon {
  height: 24px;
}

.menu-item-group.active,
.submenu-item.active {
  .menu-item {
    background-color: $eadRedContrast;
    height: max-content;
  }
  .menu-item-title {
    color: $eadRed;
  }
  .menu-item-icon svg path {
    fill: $eadRed;
  }
}

.submenu-item.active {
  .submenu-item {
    background-color: $eadRedContrast;
  }
  .submenu-icon {
    margin: 0px 9px;
  }
  .submenu-item-title {
    span {
      font-weight: 600 !important;
    }
  }
}

.submenu-item.inactive {
  .submenu-item-title {
    opacity: 2;
    color: $grey-600;
  }
}

.link-group {
  margin: 20px 0;
}

.link-group-header {
  color: $grey-500;
}

.menu-chevron-open {
  justify-content: flex-end;
}

.menu-chevron {
  justify-content: flex-end;
}

.menu-chevron svg {
  width: 18px;
  height: 18px;
  margin-top: 3px;
}

.menu-chevron-open svg {
  transform: rotate(90deg);
  width: 18px;
  height: 18px;
  margin-top: 4px;
}

