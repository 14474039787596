@import "../../../../UI/Colors.scss";
$eadRed: #b71d18;
$eadRedContrast: rgb(183, 29, 24, 0.2);

.card-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  min-width: 275px;
  padding: 16px 24px;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  cursor: pointer;
}

.card-div.active {
  background-color: $grey-100;
  border: 1px solid rgba(183, 29, 24, 0.2);
}

.title {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: hidden;
  img {
    width: 70px !important;
    height: 70px !important;
  }
}

.div1 {
  display: flex;
  gap: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey-700;
}

.qty {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: $grey-900;
}

.trend-down-icon svg path {
  fill: $eadRed;
  background-color: $eadRed;
}

.trend-up-icon svg path {
  fill: #36b37e;
  background-color: #36b37e;
}
