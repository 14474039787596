.upload-button {
    display: flex;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 8px;
    background: #FFE9D5;
    color: #7A0916 !important;
    border: none;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
    margin-top: 0px;
    margin-right: 10px;
  }
