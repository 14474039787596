$grey-300: #dfe3e8;
$grey-400: #c4cdd5;
$grey-500: #919eab;
$grey-600: #637381;
$grey-700: #454f5b;
$grey-800: #212b36;
$grey-900: #161c24;
$success: #36b37e;

.field {
  display: flex;
  flex-direction: column;
  height: 48px;
  border: 1px solid $grey-300;
  border-radius: 8px;
  padding: 0 16px;
  position: relative;
  background-color: #fff;
  .label {
    position: absolute;
    font-size: 12px;
    top: -8px;
    padding: 0 2px;
    background: #fff;
    transition: all ease-in-out 300ms;
  }
  .hidden {
    opacity: 0;
  }
  .vissible {
    opacity: 1;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    // border-radius: 16px;
    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
    }
    .icon {
      font-size: 20px;
      cursor: pointer;
    }
  }
  .options {
    position: absolute;
    top: 51px;
    background: #fff;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    z-index: 99999;
    max-height: 300px;
    overflow-y: auto;
    padding: 16px;
    gap: 8px;
    box-shadow: 0px 5px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    .option {
      // height: 20px;
      font-size: 13px;
      cursor: pointer;
    }
  }
  &:hover {
    border: 1px solid $grey-700;
  }
}
