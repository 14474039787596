@import "../../../../UI/Colors.scss";
$eadRed: #b71d18;
$eadRedContrast: rgb(183, 29, 24, 0.2);

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px 24px;
  min-width: 274px;
  display: flex;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  cursor: pointer;
}

.topSideDiv {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  gap: 20px;
  width: 98%;
  padding: 2px 20px 2px 1px;
  border-radius: 10px;
}

.topSideDiv::-webkit-scrollbar {
  display: none;
}

.title {
  box-sizing: border-box;
  width: 51px;
  height: 51px;
  border-radius: 50%;
  background-color: $grey-200;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.div1 {
  display: flex;
  gap: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey-700;
}

.qty {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: $grey-900;
}


.trend-down-icon svg path {
  fill: $eadRed;
  background-color: $eadRed;
}

.trend-up-icon svg path {
  fill: #36B37E;
  background-color: #36B37E;
}
