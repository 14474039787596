@import "../../UI/Colors.scss";

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
th {
  background-color: $grey-200;
}
td,
th {
  text-align: left;
  padding: 14px 18px;
}

th {
  color: $grey-600;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

td {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey-800;
}

th:nth-child(1) {
  width: 50%;
}

tr:hover {
  background-color: $grey-100;
}

.main {
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  background-color: #ffff;
}

.topSide {
  display: flex;
  justify-content: space-between;
  padding: 15px 22px;
  border-bottom: 1px solid #f4f6f8;
}

.secondRow {
  padding: 10px 18px;
  border-bottom: 1px solid #f4f6f8;
  background: $grey-100;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.secondRow::-webkit-scrollbar {
  display: none;
}


.secondRow1 {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.circle {
  background: rgba(0, 184, 217, 0.1);
  border-radius: 6px;
  padding: 1px 8px;
  color: #00b8d9;
  display: inline-block;
  margin-right: 5px;
}

.circleText {
  color: $grey-700;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
}

.active {
  font-weight: 700;
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #4a4a4a;
  margin-top: 3px;
}

.bottomSide {
  background: #ffffff;
  border-top: 1px solid rgba(145, 158, 171, 0.24);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 7px 14px;
  gap: 10px;
  border-radius: 0px 0px 16px 16px;
}

.side {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.bottomTxt {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey-500;
}

.icon svg {
  width: 19px;
  height: 19px;
  margin-top: 2px;
}

.btnSide {
  display: flex;
  gap: 10px;
}




