@import "../../../../UI/Colors.scss";
$eadRed: #b71d18;
$eadRedContrast: rgb(183, 29, 24, 0.2);

.trend-up-icon svg path {
  fill: $success;
  background-color: $success;
}

.status {
    padding: 2px 8px;
    border-radius: 12px;
    font-weight: 400;
    font-size: 12px;
}

.addBtn {
  display: flex;
  background: #B71D18;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;
}

.addTxt {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
}

.add-icon svg path {
  fill: #FFFFFF;
  opacity: 1;
  background-color: #FFFFFF;
  border: 2px solid white;
}

.statusDiv {
  display: flex;
  justify-content: space-between;
}

.circle-icon svg {
  width: 15px;
  height: 15px;
  margin-top: 4px;
  cursor: pointer;
}

.divv .rdt_Table {
  border: 2px solid red;
}

.imageDiv {
  width: 23px;
  height: 23px;
  border-radius: 50%;
}

.image {
  width: 100%;
  height: 100%;
}

 