@import "../../UI/Colors.scss";

.top-header {
  padding: 20px 0;
  background-color: #ffffff;
  display: flex;
  align-items: right;
  justify-content: flex-end;
  gap: 20px;
}

.top-header-user {
  display: flex;
  gap: 10px;
}

.top-header-user-img {
  width: 40px;
  height: 40px;
}

.user-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.top-header-user-name {
  line-height: 40px;
}

.top-header-user-chevron,
.top-header-staffs,
.top-header-notification {
  position: relative;
  width: 40px;
  height: 40px;
}

.top-header-user-chevron svg,
.top-header-staffs svg,
.top-header-notification svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.top-header-user-chevron {
  transform: rotate(90deg);
  background-color: #fff;
  border-radius: 20px;
}

.top-header-user-chevron:hover {
  background-color: $grey-100;
  border-radius: 20px;
}

.log-out {
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 7px;
  z-index: 10;
  padding: 10px;
  display: flex;
  width: 60px;
  margin-left: 970px;
  margin-top: -15px;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
}
