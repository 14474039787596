@import "../Colors.scss";
  .popupRoot{
    position: absolute;
    width: 100vw;
    height: 150vh;
    top: 50%;
    left: 50%;
    background-color: rgba(0, 0, 0, 0.379);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10 ;
    transform: translate(-50%, -50%);
  }

  .popupRootType3 {
    position: absolute;
    width: 100vw;
    height: 150vh;
    top: 10%;
    left: 48%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: right;
    z-index: 10 ;
    transform: translate(-50%, -50%);
  }

  .popupRootCenter{
    position: absolute;
    width: 100vw;
    height: 150vh;
    top: 50%;
    left: 50%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: right;
    z-index: 10 ;
    transform: translate(-50%, -50%);
  }

  .childComponent{
    background-color: #ffffff;
    border-radius: 7px;
    padding: 20px;
  }

  .childComponentType3{
    background-color: #ffffff;
    border-radius: 7px;
    padding: 10px;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
  }

  .childComponentCentered{
    background-color: #ffffff;
    border-radius: 7px;
    padding: 10px 10px 0px 10px;
    margin-top: 15px;
    margin-right: 120px;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
  }
  
  .popupOpen{
  display: none;
  }