@import "../../../../UI/Colors.scss";
$eadRed: #b71d18;
$eadRedContrast: rgb(183, 29, 24, 0.2);

.trend-up-icon svg path {
  fill: $success;
  background-color: $success;
}

.status {
    padding: 2px 8px;
    border-radius: 12px;
    font-weight: 400;
    font-size: 12px;
}

.addBtn {
  display: flex;
  background: #B71D18;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;
}

.addTxt {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
}

.add-icon svg path {
  fill: #FFFFFF;
  opacity: 1;
  background-color: #FFFFFF;
  border: 2px solid white;
}

.statusDiv {
  display: flex;
  justify-content: space-between;
}

.circle-icon svg {
  width: 15px;
  height: 15px;
  margin-top: 4px;
  cursor: pointer;
}


.container {
  position: relative;
  display: inline-block;
}

.actionMemuDiv {
  position: relative;
}

.actionMemu {
  position: absolute;
  z-index: 1;
}

.actionMemu svg {
  width: 15px;
  height: 15px;
  margin-top: 14px;
  cursor: pointer;
}


  .dropdown select {
    min-width: calc(100%) !important;
    padding: 16px 24px 16px 24px;
    border: none;
    font-size: 14px;
    border-radius: 8px;
    color: $grey-700;
    transition: all 0.5s ease-in-out;
    left: 0;
    right: 0;
    &:hover {
      border-color: $grey-700;
    }
  }

  .qty-div {
    margin-top: 12px;
  }

  .qty-icon {
    background: #F4F6F8;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    transform: rotate(90deg);
    cursor: pointer;
  }

  .qty-icon svg {
    width: 16px;
    height: 16px;
  }

 .inventory {
  //position: relative;
  display: flex;
  gap: 120px;
  // justify-content: space-between;
 }

 .buttons {
  display: flex;
  gap: 10px;
 }

.add-inventory {
    background-color: #f9f9f9;
    border-radius: 7px;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    // top: 50px;
    gap: 5px;
    cursor: pointer;
}

.reset-inventory {
  background-color: #B71D18;
  color: #FFFFFF;
  border-radius: 7px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  // top: 50px;
  gap: 5px;
  cursor: pointer;
}

.edit-text {
  color: #161C24;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  margin-top: -1px;
}

.reset-text {
  color: #FFFFFF;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  margin-top: -1px;
}

.edit-icon svg {
  width: 18px;
  height: 18px;
}

.edit-icon svg path {
  // fill: #0000;
  opacity: 1;
  // background-color: black;
  margin-top: 2px;
}

.second-field {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  margin: 0;
  margin-top: -20px;
}

.searchBar {
  margin-top: 17px;
}

.edit-icon svg path {
  // fill: white;
  opacity: 1;
  background-color: black;
}

.edit-icon svg path {
  fill: white;
  opacity: 1;
  background-color: white;
}