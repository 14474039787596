.modal {
    padding: 10px;
    //width: 400px;
    // height: 400px;
    display: flex;
    flex-direction: column;
}

.image {
    width: 100%;
    height: 100%;
}