@import "../../UI/Colors.scss";

.main {
  padding: 10px 0px;
}

.mains {
  padding: 10px;
  width: 600px;
  height: 100%;
}

.section2 {
  margin-top: 5px;
}

.addDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
