@import "../../../../UI/Colors.scss";

.section-one {
    margin-bottom: 0;
    position: relative;
}

.reference {
    margin-top: 14px;
}

.initiated {
    padding: 6px 12px;
    border-radius: 60px;
    color: #7a0916;
    background: #ffe9d5;
    font-weight: 700;
    font-size: 14px;
}

.delivering {
    padding: 6px 12px;
    border-radius: 60px;
    color: #ffab00;
    background: #ffab0033;
    font-weight: 700;
    font-size: 14px;
}

.awaiting_processing {
    padding: 6px 12px;
    border-radius: 60px;
    color: #ffab00;
    background: #ffab0033;
    font-weight: 700;
    font-size: 14px;
}

.processing {
    padding: 6px 12px;
    border-radius: 60px;
    color: #ffab00;
    background: #ffab0033;
    font-weight: 700;
    font-size: 14px;
}

.awaiting_packaging {
    padding: 6px 12px;
    border-radius: 60px;
    color: #ffab00;
    background: #ffab0033;
    font-weight: 700;
    font-size: 14px;
}

.paid {
    color: #36b37e;
    background: rgba(54, 179, 126, 0.2);
    padding: 6px 12px;
    border-radius: 60px;
    font-weight: 700;
    font-size: 14px;
}

.packaged {
    padding: 6px 12px;
    border-radius: 60px;
    color: #ffab00;
    background: #ffab0033;
    font-weight: 700;
    font-size: 14px;
}

.abadoned {
    padding: 6px 12px;
    border-radius: 60px;
    color: #b71d18;
    background: rgba(183, 29, 24, 0.2);
    font-weight: 700;
    font-size: 14px;
}

.status {
    display: flex;
    justify-content: space-between;
}

.send-div {
    display: flex;
    margin-top: -9px;
    cursor: pointer;
}

.status-div {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.view-payments {
    text-decoration: underline;
    font-size: 12px;
    color: #B71D18;
}

.download-div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
}

.pdf-icon svg path {
    opacity: 1;
}

.pdf-icon {
    cursor: pointer;
}

.send-payment {
    background-color: #ffffff;
    border-radius: 7px;
    z-index: 10;
    padding: 10px 10px 0px 10px;
    display: flex;
    right: 5px;
    top: 50px;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
    position: absolute;
}

.paid-div {
    display: flex;
    gap: 8px;
    margin-top: -25px;
    justify-content: center;
    align-items: center;
}

.button,
.button2 {
    padding: 6px 16px;
    font-weight: 700;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
}

.button {
    background: #B71D18;
    font-weight: 600;
    font-size: 13px;
    color: #ffffff;
}

.button2 {
    color: #005249;
    background: #D8FBDE;
}

.action-menu {
   margin-top: 20px;
   cursor: pointer;
}

.action-m {
    background-color: #ffffff;
    border-radius: 7px;
    z-index: 10;
    padding: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: -5px;
    margin-top: 100px;
    // top: 50px;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
    position: absolute;
    gap: 5px;
    cursor: pointer; 
}

.items {
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
}

.items:hover {
    background-color: $grey-100 !important;
}