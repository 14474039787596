
@import "../../UI/Colors.scss";

.main {
  padding: 10px 0px;
  }
 
  .section2 {
    margin-top: 5px;
  }
  
  .addDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }