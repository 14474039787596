@import "../../../../UI/Colors.scss";
$eadRed: #b71d18;
$eadRedContrast: rgb(183, 29, 24, 0.2);

.cardDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 16px 24px;
  display: flex;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
  height: 125px;
}

.title {
  color: $grey-900;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

.div1 {
  display: flex;
  gap: 10px;
}

.per {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey-700;
}

.qty {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: $grey-900;
}


.trend-down-icon svg path {
  fill: $eadRed;
  background-color: $eadRed;
}

.trend-up-icon svg path {
  fill: $success;
  background-color: $success;
}