@import "../../../../UI/Colors.scss";

.sendPaymentLink {
}

.section {
    background-color: $grey-200;
    width: 255px;
    color: $grey-800;
    font-weight: 400;
    font-size: 12px;
    padding: 8px;
}

.message {
    margin-top: 0;
}

.copy-icon svg path {
    fill: red;
    opacity: 1;
}

.copy-div {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $grey-600;
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
}

.paystack-name {
    margin-bottom: 0;
    margin-top: 20px;
}

.copy-img-box {
    width: 20px;
    height: 20px;
}

.copy-img {
    width: 100%;
    height: 100%;
}

.unavailable {
  margin-top: -10px;
  margin-bottom: 0;  
}

.link {
    color: #B71D18;
    //max-width: 40px;
    font-weight: 400;
    font-size: 12px;
}

.link-text {
    word-wrap: break-word;
}