
@import "../../UI/Colors.scss";

.main {
  padding: 10px 0px;
  }
 
  .section2 {
    margin-top: 5px;
  }
  
  .addDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .mains {
    padding: 10px;
    width: 370px;
    height: 100%;
  }

  .space {
    margin-top: 16px;
    width: 100%;
  }

  .warning {
    margin-top: 16px;
    font-size: 14px;
    color: #B71D18;
  }

  .side {
    margin-top: -24px;
  }