@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./Components/UI/Colors.scss";

body {
  background-color: #ffffff;
}

body::-webkit-scrollbar {
  display: none;
}

.rdt_Table {
  border-collapse: collapse !important;
}

.dOIzqm {
  position: relative;
}

.BafWS,
.bzRnkJ {
  border-radius: 0 0 16px 16px !important;
  height: 200px !important;
}

.dOIzqm::-webkit-scrollbar {
  display: none;
}

.rdt_TableCol_Sortable {
  display: flex;
  gap: 10px;
  justify-content: flex-end !important;
  direction: rtl;
}

.rdt_TableCol_Sortable > * {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.__rdt_custom_sort_icon__ {
  height: 12.5px;
  width: 10px;
  display: none;
}

.__rdt_custom_sort_icon__ svg {
  width: 10px !important;
  height: 12.5px !important;
}

.rdt_TableCol_SortableFilter {
  margin-left: auto;
}
.WQNxq {
  border-radius: 0 !important;
  border-collapse: collapse !important;
}

.rdt_TableCol {
  border-right: 1px solid #dfe3e8;
  padding: 10px 10px 10px 16px !important;
  height: max-content;
}

.rdt_TableCol:first-child {
  padding-left: 10px !important;
}

.cxcOiE:not(:last-of-type) {
  border: none !important;
}

.rdt_TableCol:last-child,
.rdt_TableCol:nth-last-child(2) {
  border-right: none !important;
}
.rdt_TableHeadRow {
  padding: 6px;
  background-color: $grey-200 !important;
  min-height: max-content !important;
  border: none;
}

.rdt_TableRow:hover {
  background-color: $grey-100 !important;
}

.rdt_TableRow,
.rdt_Pagination {
  border-color: $grey-200 !important;
}

.active {
  .__rdt_custom_sort_icon__ {
    display: block;
  }
}

.active-text,
.completed-text,
.processing-text,
.initiated-text,
.delivering-text,
.alarm-text {
  padding: 2px 8px;
  border-radius: 12px;
}

.active-text {
  color: #36b37e;
  background: rgba(54, 179, 126, 0.2);
}

.alarm-text {
  color: #b71d18;
  background: rgba(183, 29, 24, 0.2);
}

.completed-text {
  color: #ffffff;
  background: #1b806a;
}

.processing-text {
  color: #ffab00;
  background: #ffab0033;
}

.delivering-text {
  color: #ffab00;
  background: #ffab0033;
}

.initiated-text {
  color: #3366ff;
  background: #3366ff33;
}

.gKbhqU {
  border-radius: 0px 0px 16px 16px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.hoQsHK {
  margin-top: -20px;
}

.hXLGuA {
  border-radius: 0px !important;
}

// Circular bar
.CircularProgressbar .CircularProgressbar-path {
  stroke-linecap: square !important;
}

// country code picker
.react-tel-input .form-control {
  height: 49px !important;
  border-radius: 10px !important;
  width: 310px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 10px !important;
}

// select box
.css-wmy1p7-ReactDropdownSelect {
  min-height: 52px !important;
  border-radius: 10px !important;
}


