.addBtn {
    display: flex;
    background: #B71D18;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 8px;
    cursor: pointer;
  }

  .blue-bg {
    background: #3366FF;
  }
  
  .addTxt {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
  }
  
  .add-icon svg path {
    fill: #FFFFFF;
    opacity: 1;
    background-color: #FFFFFF;
    border: 2px solid white;
  }