
@import "../../UI/Colors.scss";

.main {
  padding: 10px 0px;
  }
 
  .section2 {
    margin-top: 5px;
  }
  
  .addDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .form-6grid-main {
    padding: 14px;
    display: flex;
    flex-direction: column;
    gap: 34px;
  }

  .mains {
    padding: 10px;
    width: 600px;
    height: 100%;
  }