@import "../../Colors.scss";

.form-group {
  width: 100%;
}

.form-container {
  width: 100%;
  position: relative;
  display: flex;
  select {
    min-width: calc(100%) !important;
    padding: 16px 24px 16px 24px;
    border: 1px solid $grey-300;
    font-size: 14px;
    border-radius: 8px;
    color: $grey-700;

    transition: all 0.5s ease-in-out;
    &:hover {
      border-color: $grey-700;
    }
    &:focus {
      border-color: $grey-700;
      outline: none;
      + label {
        padding: 0 3px;
        background: #ffffff;
        color: $grey-700 !important;
        top: 0%;
        color: $grey-500;
        font-size: 12px !important;
        left: 24px;
      }
    }
  }
  label {
    position: absolute;
    top: 0%;
    transform: translateY(-50%);
    font-size: 12px;
    left: 24px;
    transition: all 0.5s ease-in-out;
    padding: 0 3px;
    background: #ffffff;
    color: $grey-700 !important;
  }
}

.add-icon svg path {
  fill: #FFFFFF;
  opacity: 1;
  background-color: #FFFFFF;
  border: 2px solid white;
}

.addBtn {
  display: flex;
  background: #3366FF;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  cursor: pointer;
  color: #FFFFFF;
}